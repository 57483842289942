import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    token2: localStorage.getItem('access_token2') || null,
    tokenLocal: localStorage.getItem('access_token_local') || null,
    policy: [],
    policySpouse: [],
    pAll: [],
    shelf: [],
    rider1: '',
    rider2: '',
    agentRouteName: '',
    state: {
      AL: "Alabama",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      FL: "Florida",
      GA: "Georgia" ,    
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NC: "North Carolina",
      ND: "North Dakota",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    },
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    isEnroller(state) {
      //return JSON.parse(state.token2).includes
      return (JSON.parse(state.token2).role).toLowerCase().includes('enroller');
    },
    isAdmin(state) {
      //return JSON.parse(state.token2).includes
      return (JSON.parse(state.token2).role).toLowerCase().includes('admin');
    },
    isAgent(state) {
      //return JSON.parse(state.token2).includes
      return (JSON.parse(state.token2).role).toLowerCase().includes('agent');
    },
    isRecruiter(state) {
      //return JSON.parse(state.token2).includes
      return (JSON.parse(state.token2).role).toLowerCase().includes('recruiter');
    },
    returnRole(state) {
      return JSON.parse(state.token2).role;
    },
    returnUserName(state) {
      return JSON.parse(state.token2).name
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      
    },
    destroyToken(state) {
      state.token = null;
      state.token2 = null;
    },
    setToken2(state, token) {
      state.token2 = token;
      
    },
    addPolicy(state, policy) {
      state.policy = policy;
    },
    addPolicySpouse(state, policy) {
      state.policySpouse = policy;
    },
    addToShelf(state, sitem) {
      let itemInShelfIndex = state.shelf.findIndex(item => item.id === sitem.id);
      if(itemInShelfIndex !== -1) {
        return;
      }
      state.shelf.push(sitem);
    },
    removeFromShelf(state, index) {
      let shelfIndex = state.shelf.findIndex(item => item.id === index);
      console.log(state.shelf[shelfIndex]);
      state.shelf.splice(shelfIndex, 1);
      
    },
    updateShelf(state, shelf) {
      state.shelf = shelf;
    },
    updateShelf2(state, shelf) {
      state.shelf = [];
    }
  },
  actions: {
    clearShelf({commit}) {
      commit('updateShelf', []);
    },
    clearShelf2({commit}) {
      commit('updateShelf2', []);
    },
    destroyToken(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
      if(context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.post('/api/logout')
            .then(response => {
              localStorage.removeItem('access_token');
              localStorage.removeItem('access_token2');
              context.commit('destroyToken')
              resolve(response)
              
            })
            .catch(error => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('access_token2')
              context.commit('destroyToken')
              console.log(error);
              reject(error)
            });
        });
      }
    },
    retrieveToken(context, credentials) {
      let form = [];
      return new Promise((resolve, reject) => {
        axios.get('/api/login').then(resp => {
          form.cs = resp.data.ci;
          form.id = resp.data.id;
          form.gt = resp.data.gp;
          axios.post('/oauth/token', {
              grant_type: form.gt,
              client_id: form.id,
              client_secret: form.cs,
              username: credentials.username,
              password: credentials.password,
          
          }).then(resp2 => {
            let authBear = resp2.data.access_token;
            localStorage.setItem('access_token', authBear);
            context.commit('setToken', authBear);
            axios.get('/api/user', {
              headers: {'Authorization': 'Bearer ' + authBear} 
            }).then(user => {
              resolve();
              let st = {id: user.data.id, email: user.data.email, name: user.data.name, role: user.data.role}
              localStorage.setItem('access_token2', JSON.stringify(st));
              context.commit('setToken2',JSON.stringify(st));
            }).then(resp45 => {
              
            });
          }).catch(error => {
            
            reject(error)
          });
        });
      })
    },
  },
  modules: {
  }
})
