import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/quote',
    name: 'Quote',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Quote.vue')
  },
  {
    path: '/overview',
    name: 'Overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Overview.vue')
  },
  {
    path: '/benefits',
    name: 'Benefits',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Benefits.vue')
  },
  {
    path: '/howtoapply',
    name: 'HowToApply',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HowToApply.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/ticket',
    name: 'Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Ticket.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/quote/login',
    name: 'Login2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login2.vue')
  },
  {
    path: '/register',
    name: 'RegisterAll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterAll.vue')
  },
  {
    path: '/register/:slug',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/applied',
    name: 'Applied',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Applied.vue')
  },
  {
    path: '/logo',
    name: 'Logo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Logo.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminApp.vue'),
    meta: { requiresAuth: true  },
    children: [
        {
          path: '/',
          name: 'admin.tickets',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketList.vue'),
        },
        {
          path: '/ticketview/:id',
          name: 'admin.ticket.view',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketView.vue'),
        },
        {
          path: '/scheduled',
          name: 'admin.scheduled',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/ScheduleList.vue'),
        },
        {
          path: '/agents',
          name: 'admin.agents',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/AgentList.vue'),
        },
        {
          path: '/applicants',
          name: 'admin.applicants',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/Applicants.vue'),
        },
        {
          path: '/applicants/:id',
          name: 'admin.applicants.view',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/ApplicantsView.vue'),
        },
        {
          path: '/recruitors',
          name: 'admin.recruitors',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/RecruitorsList.vue'),
        },
        {
          path: '/userroles',
          name: 'admin.userroles',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/UsersList.vue'),
        },
        {
          path: '/recruitor/:slug/agents',
          name: 'admin.recruitor.agents',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/RecruitorAgentList.vue'),
        },
        {
          path: '/enrollers',
          name: 'admin.enrollers',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/EnrollersList.vue'),
        },
        {
          path: '/messages',
          name: 'admin.messages',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/MessageList.vue'),
        },
        {
          path: '/quoter',
          name: 'admin.quoter',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/Quoter.vue'),
        },
        {
          path: '/users/:id',
          name: 'admin.users.view',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserView.vue'),
        },
        {
          path: '/tickets/agent/:slug',
          name: 'admin.tickets.list',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketAgentList.vue'),
        },
        {
          path: '/account',
          name: 'admin.account',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserAccount.vue'),
        },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
