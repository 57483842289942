import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted';
import { ObserveVisibility } from 'vue-observe-visibility'
import moment from 'moment-timezone'


Vue.config.productionTip = false

import './assets/css/website.css';

require("./assets/css/confetti.css");


Vue.prototype.$moment = moment;
Vue.directive('observe-visibility', ObserveVisibility)

function isLoggedIn() {
  return localStorage.getItem('access_token');
}


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        name: 'Login',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

Vue.use(Toasted, {
  position: 'top-center',
  theme: 'bubble',
  duration: 700,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
