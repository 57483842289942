<template>
  <div>
    <header v-if="$route.name == 'Home' || $route.name == 'Quote' || $route.name == 'Overview' || $route.name == 'Benefits' || $route.name == 'HowToApply' || $route.name == 'Contact' || $route.name == 'Ticket' || $route.name == 'Login' || $route.name == 'Login2' || $route.name == 'Register' || $route.name == 'RegisterAll'" 
    :class="{'header-transparent' : this.$route.name != 'HowToApply'}" id=header-main>
            <nav class="navbar navbar-main navbar-expand-lg shadow" id=navbar-main
                :class="{
                    'navbar-light': this.$route.name == 'How ToApply' || this.$route.name == 'Quote' || this.$route.name == 'Ticket' || this.$route.name == 'Register' || this.$route.name == 'RegisterAll',
                    
                    'navbar-dark' : this.$route.name !== 'HowToApply' && this.$route.name !== 'Quote' && this.$route.name !== 'Ticket' && this.$route.name !== 'Register' && this.$route.name !== 'RegisterAll',
                    }"
                :style="styleObject"
                
            >
                <div class=container>
                <router-link v-if="this.$route.name == 'HowToApply'" class=navbar-brand :to="{name: 'Home'}">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </router-link>
                <router-link v-else-if="this.$route.name == 'Quote'" class=navbar-brand :to="{name: 'Home'}">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </router-link>
                <router-link v-else-if="this.$route.name == 'Ticket'" class=navbar-brand :to="{name: 'Home'}">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </router-link>
                <router-link v-else-if="this.$route.name == 'Register'" class=navbar-brand :to="{name: 'Home'}">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </router-link>
                <router-link v-else-if="this.$route.name == 'RegisterAll'" class=navbar-brand :to="{name: 'Home'}">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </router-link>
                <router-link v-else class=navbar-brand :to="{name: 'Home'}">
                    <h2 class="display-6 text-white my-4" style="font-weight:100">Visionary Inc</h2>
                </router-link>
                <button class="navbar-toggler" style="color:black;" type=button data-toggle=collapse data-target=#navbar-main-collapse aria-controls=navbar-main-collapse aria-expanded=false aria-label="Toggle navigation">
                    <i class="uil uil-bars" :class="{
                        'togglerLight' : this.$route.name !== 'HowToApply' && this.$route.name !== 'Quote' && this.$route.name !== 'Ticket' && this.$route.name !== 'Register' && this.$route.name !== 'RegisterAll',
                        'togglerDark' :  this.$route.name == 'How To Apply' || this.$route.name == 'Quote' || this.$route.name == 'Ticket' || this.$route.name == 'Register' || this.$route.name == 'RegisterAll',
                        }"></i>
                </button>
                <div class="collapse navbar-collapse navbar-collapse-overlay" id=navbar-main-collapse>
                    <div class=position-relative>
                        <button class=navbar-toggler style="color:black;" type=button data-toggle=collapse data-target=#navbar-main-collapse aria-controls=navbar-main-collapse aria-expanded=false aria-label="Toggle navigation">
                            <i class="uil uil-multiply" data-feather=x></i>
                         
                        </button>
                    </div>
                    <ul class="navbar-nav ml-lg-auto">
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name: 'Home'}" :class="{'navlink-black' : this.$route.name == 'HowToApply'}">Home</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name: 'Overview'}" :class="{'navlink-black' : this.$route.name == 'HowToApply'}">Overview</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class=nav-link :to="{name:'Benefits'}">Benefits</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class=nav-link :to="{name: 'HowToApply'}">How to Apply</router-link>
                        </li>
                        
                        
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class=nav-link :to="{name: 'Contact'}">Contact Us</router-link>
                        </li>
                        
                        
                       
                    </ul>
                    <ul class="navbar-nav align-items-lg-center d-lg-flex ml-lg-auto d-sm-none d-sm-none2 d-xs-none">
                        <li class=nav-item>
                            <router-link v-if="!this.$store.getters.loggedIn" :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class=nav-link :to="{name: 'RegisterAll'}">
                                Register
                            </router-link>
                        </li>
                        <li class=nav-item>
                            <router-link v-if="!this.$store.getters.loggedIn" :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class=nav-link :to="{name: 'Login'}">
                                Login
                            </router-link>
                        </li>
                        
                        <li v-if="this.$store.getters.loggedIn" class="nav-item">
                            <router-link :to="{name: 'admin.tickets'}" class="btn btn-sm btn-white btn-icon ml-3">
                                <span class="btn-inner--icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                </span> 
                                <span class="btn-inner--text">Portal</span>
                            </router-link>
                        </li>
                        <li v-if="this.$store.getters.loggedIn" class="nav-item">
                            <button @click="logStateOut" class="btn btn-sm btn-primary btn-icon ml-3">
                                <span v-if="!outLoading" class="btn-inner--icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                </span> 
                                <span v-if="!outLoading" class="btn-inner--text">Logout</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </li>
                        
                        
                    </ul>
                    <div v-if="this.$store.getters.loggedIn" class="d-lg-none px-4 pb-3 text-center">
                        <router-link :to="{name: 'admin.tickets'}" class="btn btn-block btn-outline-dark btn-icon">
                            <span class="btn-inner--icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                            </span> 
                            <span class="btn-inner--text">Portal</span>
                        </router-link>
                    </div>
                    <div v-if="!this.$store.getters.loggedIn" class="d-lg-none px-4 pb-3 text-center">
                        <router-link :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class="btn btn-block btn-outline-info btn-icon" :to="{name: 'RegisterAll'}">
                            Register
                        </router-link>
                    </div>
                    <div v-if="this.$store.getters.loggedIn" class="d-lg-none px-4 text-center">
                        
                        <button @click="logStateOut" class="btn btn-block btn-sm btn-primary">
                                
                                <span v-if="!outLoading" class="btn-inner--text">Logout</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                    </div>
                    <div v-if="!this.$store.getters.loggedIn" class="d-lg-none px-4 text-center">
                        
                       <router-link v-if="!this.$store.getters.loggedIn" :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class="btn btn-block btn-sm btn-primary" :to="{name: 'Login'}">
                            Login
                        </router-link>
                    </div>
            </div>
        </div>
    </nav>
   </header>
    <router-view/>
    <footer v-if="this.$route.name !== 'Login'" class=position-relative id=footer-main>
    <div class="footer pt-lg-7 footer-dark bg-dark">
        <div class="shape-container shape-line shape-position-top shape-orientation-inverse">
            <svg width=2560px height=100px xmlns=http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink preserveAspectRatio=none x=0px y=0px viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100" xml:space=preserve class=""><polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
        
        <div class="container pt-4">
            <div class="row justify-content-center">
                <div class=col-lg-12><div class="row align-items-center">
                    <div class=col-lg-7><h3 class="text-secondary mb-2">Guaranteed Whole Life Insurance</h3>
                        <p class="lead mb-0 text-white opacity-8">Individual Policy</p>
                    </div>
                    <div class="col-lg-5 text-lg-right mt-4 mt-lg-0">
                       
                        
                    </div>
                </div>
            </div>
        </div>
        <hr class="divider divider-fade divider-dark my-5">
        <div class="row justify-content-center">
            <div class="col-lg-10 mb-5 mb-lg-0">
                
                <p class="mt-4 text-sm opacity-8 pr-lg-4">
                </p>
                <p>
                    This is an Individual Whole Life plan for those individuals who at the time of application are working a minimum of 20+ hours per week.  The Planned monthly costs/ premiums are Guaranteed not to increase and the death benefit will not reduce as long as monthly planned costs are paid.  Coverage begins immediately on the issue date of the policy. 
                    Base plan pays a death claim if death occurs by natural causes or accident.  There is included in the cost up to age 55 an Accidental Death Benefit that can double or triple the amount paid to the beneficiary.  This Whole Life Plan builds cash value at a guaranteed rate of 3% and a current rate of 4.25%.  This plan is available in the United States except for the states of Hawaii, Alaska and New York.
                </p>
                
        </div>
        
        
        
    </div>
    <hr class="divider divider-fade divider-dark my-4">
    <div class="row align-items-center justify-content-md-between pb-4">
        <div class=col-md-6>
            <div class="copyright text-sm font-weight-bold text-center text-md-left">&copy; 2020 
                <a href=https://merginggrowth.com class=font-weight-bold target=_blank>Merging Growth</a>. All rights reserved
            </div>
        </div>
        <div class=col-md-6>
            <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                <li class=nav-item>
                    <a class=nav-link href=#>Terms</a>
                </li>
                <li class=nav-item>
                    <a class=nav-link href=#>Privacy</a>
                </li>
                <li class=nav-item>
                    <a class=nav-link href=#>Cookies</a>
                </li>
            </ul>
        </div>
    </div>
</div>
</div>
</footer>
  </div>
</template>
<script>
import axios from 'axios';

axios.defaults.baseURL = 'https://vapi.visionarylifeinsurance.com';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
//axios.defaults.baseURL = 'http://vapi.test.life:8000';

export default {
    data() {
        return {
            outLoading: false,
        }
    },
    watch: {
        '$route' () {
            $('#navbar-main-collapse').collapse('hide');
            window.scrollTo(0,0);
        }
    },
    computed: {
        styleObject() {
            if(this.$route.name == 'HowToApply') {
                return {background: 'white'};
            }
        },
    },
    methods: {
        
        logStateOut() {
            this.outLoading = true;
            this.$store.dispatch('destroyToken').then(resp => {
                console.log(resp.data);
                this.$router.push({name: 'Home'})
                this.outLoading = false;
            }).then(resp => { this.$router.push({name: 'Home'}) }).catch(err => { this.outLoading = false; this.$router.push({name: 'Home'}) });
        },
    }
}
</script>
<style>
 .navlink-black {
     color:black;
 }

 .togglerLight {
     color:rgba(255,255,255,0.8);font-size:1.45rem;
 }
  .togglerDark {
     color:rgba(0,0,0,0.8);font-size:1.45rem;
 }
 @media (max-width:576px){
    .d-sm-none2{display:none!important}
 }
</style>
